<!--
 * @Author: yanmin
 * @Description: 输入车牌
 * @Date: 2022-04-14 11:15:03
 * @LastEditTime: 2024-04-18 15:03:48
 * @FilePath: \yf_car\src\views\prepay.vue
-->
<!--  -->
<template>
  <div id="g-bigvue-box" class="prepay">
    <i class="u-blue-bg"></i>
    <div class="g-white-box g-stop-payment">
      <img src="../assets/images/car-img.png" class="u-getinto-img" />
      <h3 class="u-inputcarid-tips">车牌后三位</h3>
      <div id="keyboard">
        <div class="content-car-number">
          <div class="content-section">
            <div class="content-section-flex flex-card">
              <div class="content-section-flex flex-card-border">
                <button id="numFour" @click="btnClickNum('four')" v-bind:class="{isNumClick: isNumFour}">
                  {{ numFour }}
                </button>
                <button id="numFive" @click="btnClickNum('five')" v-bind:class="{isNumClick: isNumFive}">
                  {{ numFive }}
                </button>
                <button v-if="checkbox" id="numSix" @click="btnClickNum('six')" v-bind:class="{isNumClick: isNumSix}">
                  <!-- <strong v-if="numSix == '' ">新能源</strong> -->
                  {{ numSix }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="keyboard" :style="{display: keyboardShow ? 'block' : 'block'}" :class="{animationDown: isDown, animationUp: isUp}">
          <button class="btn-complete" @click="completeClick"><span>完成</span></button>
          <!-- <div class="m-keyboard-box" v-if="keyboard == 'txt'">
                    <div class="keyboard-row" v-for="(item,rows) in carTxt">
                        <button class="keyboard-row-item" v-for="(i,index) in item.name" @click="btnWordClick(rows,index,i)">{{i}}</button>
                    </div>
                    <div class="keyboard-row">
                        <button class="keyboard-row-item bottom" @click="btnBottomClick('新')">新</button>
                        <button class="keyboard-row-item bottom" @click="btnBottomClick('临')">临</button>
                        <button v-for="j in noneBottomtxt" class="keyboard-row-item none-botton">{{j}}</button>
                        <div class="keyboard-row-item clear" @click="clearClick">删</div>
                    </div>
                </div> -->
          <div class="m-keyboard-box" v-if="keyboard == 'num'">
            <div class="keyboard-row" v-for="(item, rows) in carNum">
              <button
                :disabled="!isSelectl ? isDisable && i < 10 : isDisable && rows != 0"
                class="keyboard-row-item"
                v-for="(i, index) in item.name"
                @click="btnWordClick(rows, index, i)"
              >
                {{ i }}
              </button>
            </div>
            <div class="keyboard-row">
              <!-- <div class="keyboard-row-bottom"> -->
              <button :disabled="isSelectl" v-for="item in carNumBottom" class="keyboard-row-item bottom" @click="btnBottomNumClick(item)">
                {{ item }}
              </button>
              <button v-for="j in noneBottom" class="keyboard-row-item none-botton">{{ j }}</button>
              <div class="keyboard-row-item clear" @click="clearClick">删</div>
              <!-- </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="g-btn f-btncolor-blue" :class="{ fbtncolorgay: !iscarId }" @click="searchCarList()">
          {{ isLogin ? '查询停车费' : '请授权登录' }}
        </div> -->
        <van-button type="primary" class="g-btn f-btncolor-blue" @click="searchCarList()"> {{ isLogin ? '查询停车费' : '请授权登录' }}</van-button>
        <!-- <div class="no-plate" @click="noCarPay" v-if="isNoCarPay">无牌车支付</div> -->
        <div class="g-history-box">
          <!-- <p class="u-history-tips">历史车牌，点击直接历史车牌，点击直接缴费</p> -->
          <ul class="m-history-btn">
            <li v-for="(val, i) in historyCar" v-if="historyCar.length > 0">
              <b @click="gotoYpayment(val)">{{ val }}</b>
              <i @click="deleteShow(i)"></i>
            </li>
            <li v-if="temporary_car_num">
              <b @click="gotoYpayment(temporary_car_num)">{{ temporary_car_num }}</b>
            </li>
          </ul>
        </div>
        <!-- 扫码提示 -->
        <!-- <scancode :scancodeshow="scancodeshow"></scancode> -->
      </div>
    </div>
    <div class="g-foot-box">
      <footdiv ref="footForm" :passageway-id="passageway_id"></footdiv>
    </div>
    <div class="g-alert-box" v-if="alertShow" :style="{display: alertShow ? 'block' : 'none'}">
      <div class="m-alert-cont">
        <span v-if="isTips">
          <h3 class="u-alert-title">{{ title }}</h3>
          <p class="u-alert-p">{{ tipsfont }}</p>
          <b class="g-btn f-btncolor-blue u-alert-btn" @click="hideAlert()">我知道了！</b>
        </span>
        <span v-if="isDelete">
          <h3 class="u-alert-title">{{ deletefont }}</h3>
          <ul class="m-alert-ok">
            <li @click="hideAlert()">取消</li>
            <li @click="deleteHistoryOk()">确定</li>
          </ul>
        </span>
        <span v-if="isChooseCar">
          <h3 class="u-alert-title">请选择您的车牌</h3>
          <ul class="m-chooseCarList-ul">
            <li v-for="(val, i) in chooseCarList" :key="i" @click="chooseCarId(val.number)">{{ val.number }}</li>
          </ul>
          <ul class="m-alert-ok">
            <li @click="hideAlert()" class="f-cancel-btn">都不是</li>
          </ul>
        </span>
      </div>
      <i class="u-alert-bg" @click="hideAlert()"></i>
    </div>
    <van-popup v-model="searchCaridShow" position="bottom" round lock-scroll lazy-render class="g-showcar-box">
      <div style="width: 100%; position: relative">
        <ul class="ul-select">
          <li v-for="(i, index) in searchCaridList" :key="i.car_id" @click="searchCarImg(i)">
            <div class="serial_num">{{ index + 1 }}</div>
            <div class="lt-img">
              <div @click.stop="lookImg(i.entry_img)" style="width: 100%; height: 100%; border-radius: 5px; overflow: hidden; position: relative">
                <van-image width="100%" height="100%" lazy-load fit="cover" :src="i.entry_img || errorImg" />
              </div>
            </div>
            <div class="rt-ct">
              <div class="rt-ct-info rt-ct-mg">
                <span>
                  <span class="num">
                    {{ i.car_number.substr(0, i.car_number.length - 3) }}<i>{{ i.car_number.substr(-3, 3) }}</i>
                  </span>
                </span>
                <span class="navigation">缴费</span>
              </div>
              <div class="rt-ct-info"><span></span>{{ parseTime(i.entry_time, '{y}年{m}月{d}日 {h}:{i}:{s}') }}入场</div>
            </div>
          </li>
        </ul>
        <div class="prompt-info">找到{{ searchCaridList.length }}个结果</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import api from '@/api'
import {Toast, Button, ImagePreview} from 'vant'
import '@/assets/style/keyboard.css'
import {removeObjNull, browserName, urlEncode, urlParse, parseTime} from '@/utils/base'
import {setToken, getToken, setSmarkId, removeSmarkId, setAppid, getAppid} from '@/utils/auth'
const {
  base: {getCarNumberListInStoping, getOrdersInfoByCarNumber, getConsumeInfo, getPayInfo, getAppidData, notCarNumberExport, getCarOwnerHisTempCarNumber},
} = api
//车牌号验证方法
function isVehicleNumber(vehicleNumber, size) {
  var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
  var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
  if (size == 7) {
    return creg.test(vehicleNumber)
  } else if (size == 8) {
    return xreg.test(vehicleNumber)
  } else {
    return false
  }
}
export default {
  components: {
    Toast,
    Button,
  },
  data() {
    return {
      baseName: process.env.VUE_APP_BASE_API,
      formData: {
        smartpark_id: '',
        from: browserName(),
        h5_url: encodeURIComponent(location.href),
      },
      errorImg: require('@/assets/images/notimg.png'),
      title: '出场预支付',
      alertShow: false, //提示弹窗背景
      isTips: false, //单纯提示弹窗
      title: '温馨提示', //提示弹窗文字
      tipsfont: '',
      isDelete: false, //删除提示弹窗
      deletefont: '是否确认删除',
      isChooseCar: false, //选择车牌弹窗
      chooseCarList: [], //选择车牌列表
      deleteHistoryId: 0, //确认删除历史ID
      isLogin: false,
      loading: false,
      parseTime: parseTime,
      historyCar: [],
      //输入键盘
      toastTxt: '',
      isToastShow: false,
      isStr: '20px',
      isEnd: '300px',
      ispickerDateShow: '-400px',
      dateTitle: '开始',
      beginTimeVal: '',
      overTimeVal: '',
      isFeeHourClick: true,
      checkbox: true,
      isDown: false,
      isUp: true,
      selected: null,
      isSelectx: false,
      isSelectl: false,
      key: '1',
      areaName: '',
      areaLetter: '',
      numOne: '',
      numTwo: '',
      numThree: '',
      numFour: '',
      numFive: '',
      numSix: '',
      isYue: false,
      keyboardShow: true,
      keyboard: 'num',
      isA: false,
      isNumOne: false,
      isNumTwo: false,
      isNumThree: false,
      isNumFour: false,
      isNumFive: false,
      isNumSix: false,
      isDisable: false,
      carTxt: [
        {
          name: ['粤', '京', '冀', '沪', '津', '晋', '蒙', '辽', '吉', '黑'],
        },
        {
          name: ['苏', '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '桂'],
        },
        {
          name: ['琼', '渝', '川', '贵', '云', '藏', '陕', '甘', '青', '宁'],
        },
      ],
      carNumBottom: ['W', 'X', 'Y', 'Z'],
      noneBottom: ['', '', '', ''],
      noneBottomtxt: ['', '', '', '', '', ''],
      carNum: [
        {
          name: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        },
        {
          name: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'],
        },
        {
          name: ['L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'],
        },
      ],
      iscarId: true, //是否有车牌
      addedCarNum: '', //添加的车牌号码
      smartpark_id: '', //园区ID
      scancodeshow: false, //非微信和支付宝弹层，控制显示
      temporary_car_num: '',
      searchCaridShow: false,
      searchCaridList: [],
    }
  },
  computed: {
    changeData() {
      const {numFour, numFive, numSix} = this
      return {
        numFour,
        numFive,
        numSix,
      }
    },
    isNoCarPay() {
      if (this.$route.query.passageway_id) {
        return true
      }
    },
    passageway_id() {
      if (this.$route.query.passageway_id) {
        return this.$route.query.passageway_id
      } else {
        return false
      }
    },
  },
  watch: {
    checkbox: function (val, oldval) {
      //监听是否为新能源
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      const vm = this
      if (!val) {
        // 切换到普通车牌
        if (vm.numFour) {
          vm.isNumFive = true
          vm.key = 7
        }
      } else {
        // 切换到新能源车牌
        if (vm.numFive) {
          vm.isNumSix = true
          vm.key = 8
        }
      }
    },
    numFive: function (val) {
      //监听是否为普通车牌最后一位
      if (!this.checkbox && val) {
        this.isUp = false
        this.isDown = true
        this.keyboardShow = false
        this.isNumFive = false
      }
    },
    numSix: function (val) {
      //监听是否为新能源车牌最后一位
      if (this.checkbox && val) {
        this.isUp = false
        this.isDown = true
        this.keyboardShow = false
        this.isNumSix = false
      }
    },
    changeData(val) {
      let index = 0
      Object.keys(val).map(ele => {
        if (val[ele] !== '') {
          index++
        }
      })
      if (index === 3) {
        this.getCarNumList()
      }
      //   console.log(222, index)
    },
  },
  methods: {
    async searchCarImg(item) {
      if (this.historyCar.indexOf(item.car_number) == -1) {
        this.historyCar.push(item.car_number)
      }
      if (this.historyCar.length > 5) {
        //大于5个历史记录就删除第一个
        this.$delete(this.historyCar, 0) //删除数组里面对应的历史记录
      }
      // console.log('历史记录', this.historyCar)
      window.localStorage.setItem('lsCarId', this.historyCar)
      await this.gotoYpayment(item.car_number)

      // this.findcarData = item
      // this.endNum = item.parking_number
      //  this.$refs.selectParking1.item = item.parking_number
      // this.car_id = item.car_id
      // this.disable = true
      // this.findcarShow = true
    },
    lookImg(img) {
      this.validateImage(img ? img : '')
    },
    validateImage(imgurl) {
      let that = this
      var ImgObj = new Image()
      ImgObj.onload = function () {
        ImagePreview([imgurl])
      }
      ImgObj.onerror = function () {}
      ImgObj.src = imgurl
    },
    noCarPay() {
      let passageway_id = this.$route.query.passageway_id
      notCarNumberExport({
        passageway_id: passageway_id,
      }).then(res => {
        if (res.code === 1) {
          this.$router.push({path: '/leave', query: {passageway_id: passageway_id}})
        }
      })
    },
    //影藏提示框
    hideAlert() {
      this.alertShow = false
    },
    //删除历史ID
    deleteHistoryOk() {
      this.alertShow = false //隐藏弹窗
      this.$delete(this.historyCar, this.deleteHistoryId) //删除数组里面对应的历史记录
      window.localStorage.setItem('lsCarId', this.historyCar) //重新缓存数组
    },
    // 车牌类型切换
    carTypeChange(val) {
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      if (!val) {
        // 切换到普通车牌
        if (this.numFour) {
          this.isNumFive = true
          this.key = 7
        }
      } else {
        // 切换到新能源车牌
        if (this.numFive) {
          this.isNumSix = true
          this.key = 8
        }
      }
    },
    // 车牌号第一位输入框选择
    btnClickYue() {
      this.isYue = true
      this.isA = false
      this.isUp = true
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.keyboardShow = true
      this.keyboard = 'txt'
      this.key = 1
    },
    // 键盘选择显示
    btnWordClick(rows, index, i) {
      this.selected = i
      if (this.key === 1) {
        if (this.areaName === '临') {
          this.areaLetter = ''
          this.numOne = ''
          this.numTwo = ''
          this.numThree = ''
          this.numFour = ''
          this.numFive = ''
          this.numSix = ''
        }
        this.areaName = i
        this.isSelectl = false
        document.getElementById('letter').click()
      } else if (this.key === 2) {
        this.areaLetter = i
        document.getElementById('numOne').click()
      } else if (this.key === 3) {
        this.numOne = i
        document.getElementById('numTwo').click()
      } else if (this.key === 4) {
        this.numTwo = i
        document.getElementById('numThree').click()
      } else if (this.key === 5) {
        this.numThree = i
        document.getElementById('numFour').click()
      } else if (this.key === 6) {
        this.numFour = i
        document.getElementById('numFive').click()
      } else if (this.key === 7) {
        this.numFive = i
        if (this.checkbox) {
          document.getElementById('numSix').click()
        }
      } else if (this.key === 8) {
        this.numSix = i
      }
      if (this.key === 7 || this.key === 8) {
        this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
        this.noneBottom = ['']
      } else if (this.key === 3 || this.key === 4 || this.key === 5 || this.key === 6) {
        this.carNumBottom = ['W', 'X', 'Y', 'Z']
        this.noneBottom = ['', '', '', '']
      }
    },
    // 车牌第一位选择‘临’,’新‘后的键盘显示
    btnBottomClick(val) {
      if (val === '新') {
        if (this.areaName === '临') {
          this.areaLetter = ''
          this.numOne = ''
          this.numTwo = ''
          this.numThree = ''
          this.numFour = ''
          this.numFive = ''
          this.numSix = ''
        }
        this.areaName = '新'
        this.isSelectx = true
        this.isSelectl = false
        document.getElementById('letter').click()
      } else if (val === '临') {
        this.areaName = '临'
        this.isSelectl = true
        this.isSelectx = false
        this.isDisable = true
        this.areaLetter = ''
        this.numOne = ''
        this.numTwo = ''
        this.numThree = ''
        this.numFour = ''
        this.numFive = ''
        this.numSix = ''
        document.getElementById('letter').click('isLin')
      }
    },
    // 键盘最后一行字母点击事件
    btnBottomNumClick(i) {
      this.selected = i
      if (this.key === 2) {
        this.areaLetter = i
        document.getElementById('numOne').click()
      } else if (this.key === 3) {
        this.numOne = i
        document.getElementById('numTwo').click()
      } else if (this.key === 4) {
        this.numTwo = i
        document.getElementById('numThree').click()
      } else if (this.key === 5) {
        this.numThree = i
        document.getElementById('numFour').click()
      } else if (this.key === 6) {
        this.numFour = i
        document.getElementById('numFive').click()
      } else if (this.key === 7) {
        this.numFive = i
        if (this.checkbox) {
          document.getElementById('numSix').click()
        }
      } else if (this.key === 8) {
        this.numSix = i
      }
    },
    // 车牌号第二位输入框选择
    btnClickA() {
      this.isDisable = true
      this.isA = true
      this.isYue = false
      this.isUp = true
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.keyboardShow = true
      this.keyboard = 'num'
      this.key = 2
    },
    // 车牌号后五位或六位输入框选择
    btnClickNum(name) {
      if (this.isSelectl) {
        this.isDisable = true
      } else {
        this.isDisable = false
      }
      this.keyboard = 'num'
      this.keyboardShow = true
      this.isYue = false
      this.isA = false
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.isUp = true
      // console.log(name)
      if (name === 'one') {
        this.isNumOne = true
        this.key = 3
      } else if (name === 'two') {
        this.isNumTwo = true
        this.key = 4
      } else if (name === 'three') {
        this.isNumThree = true
        this.key = 5
      } else if (name === 'four') {
        this.isNumFour = true
        this.key = 6
      } else if (name === 'five') {
        this.isNumFive = true
        this.key = 7
      } else if (name === 'six') {
        this.isNumSix = true
        this.key = 8
      }
      if (name === 'five' || name === 'six') {
        this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
        this.noneBottom = ['']
      } else {
        this.carNumBottom = ['W', 'X', 'Y', 'Z']
        this.noneBottom = ['', '', '', '']
      }
    },
    // 键盘点击‘完成’
    completeClick() {
      this.isYue = false
      this.isA = false
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.isUp = false
      this.isDown = true
      this.keyboardShow = false
    },
    // 键盘点击‘删除图标’
    clearClick() {
      // if (this.key === 1) {
      //     this.areaName = ''
      // } else if (this.key === 2) {
      //     document.getElementById('font').click()
      //     this.areaLetter = ''
      // } else if (this.key === 3) {
      //     document.getElementById('letter').click()
      //     this.numOne = ''
      // } else if (this.key === 4) {
      //     document.getElementById('numOne').click()
      //     this.numTwo = ''
      // } else if (this.key === 5) {
      //     document.getElementById('numTwo').click()
      //     this.numThree = ''
      // } else
      if (this.key === 6) {
        // document.getElementById('numThree').click()
        this.numFour = ''
      } else if (this.key === 7) {
        document.getElementById('numFour').click()
        this.numFive = ''
      } else if (this.key === 8) {
        document.getElementById('numFive').click()
        this.numSix = ''
      }
    },
    //
    async gotoYpayment(num) {
      const {data, code} = await getOrdersInfoByCarNumber({
        car_number: num,
        smartpark_id: this.formData.smartpark_id,
        passageway_id: this.$route.query.passageway_id,
      })
      if (!code) {
        Toast(`无该车牌订单`)
        return
      }
      if (!Object.keys(data).length) {
        Toast(`无该车牌订单`)
        return
      }
      // console.log(44, data)
      this.chooseCarId(num)
    },
    searchCarList() {
      if (this.isLogin) {
        this.getCarNumList()
      } else {
        this.init()
      }
    },
    chooseCarId(num) {
      // this.isChooseCar = false
      // this.alertShow = false
      this.$router.push({
        path: '/leave',
        query: {
          smartpark_id: this.formData.smartpark_id,
          car_number: num,
          passageway_id: this.$route.query.passageway_id,
        },
      })
    },
    //点击显示删除提示
    deleteShow(i) {
      this.isChooseCar = false
      this.alertShow = true
      this.isDelete = true
      this.isTips = false
      this.deleteHistoryId = i //把键值保存起来，便于后面弹窗删除
    },
    async init() {
      let name = browserName()
      // console.log('navigator', navigator.userAgent.toLowerCase())
      const {smartpark_id = ''} = urlParse()
      this.isNumFour = true
      this.key = 6
      let strName = ''
      this.loading = true
      if (smartpark_id) {
        this.formData.smartpark_id = smartpark_id
        const {data} = await getAppidData({
          smartpark_id: this.formData.smartpark_id,
          from: name,
        })
        setSmarkId(smartpark_id)
        setAppid(data.app_id)
        if (getToken() && getToken() !== 'undefined') {
          this.isLogin = true
          this.loading = false
          let CarOwner = await getCarOwnerHisTempCarNumber()
          this.temporary_car_num = CarOwner.data.car_number
          // this.$refs.footForm.phoneFn()
          //   this.getCarNum()
        } else {
          const {token, app_id = ''} = urlParse()
          if (token && app_id) {
            setToken(token, app_id)
            if (app_id === getAppid()) {
              this.isLogin = true
              this.loading = false
              let CarOwner = await getCarOwnerHisTempCarNumber()
              this.temporary_car_num = CarOwner.data.car_number
              //   this.isLogin = true
              //   this.isCar = true
              // this.$refs.footForm.phoneFn()
              //   this.getCarNum()
            } else {
              strName = urlEncode(removeObjNull(this.formData))
              if (name === 'others') {
                Toast('请用支付宝或者微信打开授权')
              } else {
                window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
              }
            }
          } else {
            strName = urlEncode(removeObjNull(this.formData))
            if (name === 'others') {
              Toast('请用支付宝或者微信打开授权')
            } else {
              // console.log('url', process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
              window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
            }
          }
        }
      } else {
        Toast('园区获取错误')
      }
    },
    getlinshiCar() {
      getCarOwnerHisTempCarNumber().then(res => {
        this.temporary_car_num = res.data.car_number
      })
    },
    // 获取车牌列表
    async getCarNumList() {
      // console.log('1122', this.numFour)
      // console.log('333', this.numFive)
      // console.log('33223', this.numSix)
      this.getlinshiCar()
      let num = this.numFour + this.numFive + this.numSix
      if (num.length < 3) {
        Toast('请填写车牌后三位')
      } else {
        const {data, msg, code} = await getCarNumberListInStoping({
          smartpark_id: this.formData.smartpark_id,
          car_number: num,
        })
        if (code === 1) {
          if (JSON.stringify(data) !== '{}') {
            // this.alertShow = true
            // this.isChooseCar = true
            // this.isDelete = false;
            // this.isTips = false;
            this.chooseCarList = data
            // let chooseData = data[0]
            this.searchCaridList = data
            if (this.chooseCarList.length === 1) {
              // this.searchCaridShow = false
              this.chooseCarList.map(ele => {
                let carId = ele.number
                if (this.historyCar.indexOf(carId) == -1) {
                  this.historyCar.push(carId)
                }
              })
              if (this.historyCar.length > 5) {
                //大于5个历史记录就删除第一个
                this.$delete(this.historyCar, 0) //删除数组里面对应的历史记录
              }
              // console.log('历史记录', this.historyCar)
              window.localStorage.setItem('lsCarId', this.historyCar)
              this.searchCaridShow = true
              // let num = this.chooseCarList[0].number
              // this.chooseCarId(num)
            } else {
              this.searchCaridShow = true
            }
          } else {
            Toast(`无该车牌订单`)
          }
        } else {
          this.alertShow = true
          this.isTips = true
          this.isChooseCar = false
          this.isDelete = false
          this.tipsfont = msg
          Toast(msg)
        }
      }
      // console.log(0, num)

      // console.log('111', data)
    },
  },
  created() {
    var lsHistory = window.localStorage.getItem('lsCarId')
    if (typeof lsHistory != 'undefined' && lsHistory != 'undefined' && lsHistory != null) {
      lsHistory = lsHistory.split(',')
      if (lsHistory[0] == '') {
        lsHistory = []
      }
      // console.log(11, lsHistory)
      this.historyCar = lsHistory //获取缓存的车牌，并且转成数组
      // console.log(88, this.historyCar)
    }
  },
  mounted() {
    this.init()
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    removeSmarkId()
  },
  activated() {},
}
</script>
<style lang="scss" scoped>
.prepay {
  padding-top: 0.1px;
}
.no-plate {
  margin-top: 10px;
  color: #1d72e8;
  text-align: center;
  font-size: 0.3rem;
}
.g-showcar-box {
  // padding: 47px;
  .ul-select {
    margin-top: 0.53333rem;
    margin-bottom: 1rem;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 0.4rem;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      border-bottom: 1px solid #ebebeb;
    }
    li:last-of-type {
      margin-bottom: 0px;
      border-bottom: 0;
    }
    .serial_num {
      font-size: 0.3rem;
      color: #878787;
      margin-right: 0.15rem;
    }
    .lt-img {
      padding: 0.2rem 0;
      width: 2rem;
      height: 1.3rem;
      border-radius: 0.13333rem;
      overflow: hidden;
      position: relative;
      ::v-deep .van-image__error {
        background: url('../assets/images/notimg.png') no-repeat center center;
        background-size: 90%;
      }
      ::v-deep .van-image__error-icon {
        display: none;
      }
    }
    .rt-ct {
      flex: 1;
      &-info {
        font-size: 0.22rem;
        color: #b1b6c4;
        line-height: 0.4rem;
        span {
          color: #b1b6c4;
          margin-left: 0.26667rem;
          .num {
            color: #000;
            margin-left: 0px;
            font-size: 0.3rem;
            i {
              color: #000;
              font-weight: bold;
            }
          }
        }
        .navigation {
          display: block;
          width: 1rem;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          border: 0.01333rem solid #000;
          border-radius: 0.06667rem;
          color: #000;
        }
      }
      &-mg {
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .prompt-info {
    position: absolute;
    width: 100%;
    bottom: -1rem;
    font-size: 0.23rem;
    line-height: 0.5rem;
    text-align: center;
    background-image: -webkit-linear-gradient(bottom, #cfd9df 0%, #e2ebf0 100%);
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  }
}
</style>
